import MediaPreviewFile from '../MediaPreviewFile/MediaPreviewFile.js';
import MediaPreviewImage from '../MediaPreviewImage/MediaPreviewImage.js';

function MediaPreviewPdf({
  url,
  ogImage,
  defaultPreviewDeleted,
  mediaPreviewUrl,
}: {
  url: string;
  ogImage?: string;
  defaultPreviewDeleted?: boolean;
  mediaPreviewUrl?: string;
}) {
  if (mediaPreviewUrl) {
    return <MediaPreviewImage url={mediaPreviewUrl} mediaLinkUrl={url} />;
  }

  if (ogImage && !defaultPreviewDeleted) {
    return <MediaPreviewImage url={ogImage} mediaLinkUrl={url} />;
  }

  return (
    <MediaPreviewFile
      url={url}
      icon="/assets/images/icon-pdf.svg"
      iconSize={20}
      defaultPreviewDeleted={defaultPreviewDeleted}
    />
  );
}

export default MediaPreviewPdf;
