export const getFormErrorMessage = (error: any, t: (value: string) => string) => {
  if (!error) {
    return '';
  }
  if (error instanceof Error) {
    return error.message;
  }
  if (error && error.code === 'E_PROFESSIONS_REQUIRED') {
    return t('common.professionsRequired');
  }
  if (error && error.code === 'E_TOO_MANY_INVITES_IN_BATCH') {
    return t('common.tooManyInvitesInBatch');
  }
  if (error && error.code === 'E_TOO_MANY_INVITES_IN_HOUR') {
    return t('common.tooManyInvitesInHour');
  }
  if (error && error.code === 'E_INVALID_WRITE_ACCESS') {
    return t('common.invalidWriteAccess');
  }
  if (error && error.code === 'E_BAD_IMAGE') {
    return t('common.badImage');
  }
  if (error && error.code === 'E_INVALID_PADLET_URL') {
    return t('common.invalidPadletUrl');
  }
  if (error && error.code === 'E_PADLET_URL_NOT_FOUND') {
    return t('common.padletUrlNotFound');
  }
  if (error && error.code === 'E_PADLET_IMPORT_ERROR') {
    return t('common.padletBoardImportFailure');
  }
  if (error && error.code === 'E_PASSWORD_REQUIRED') {
    return t('common.passwordRequired');
  }
  if (error && error.code === 'E_INTERNAL_SERVER_ERROR') {
    return t('common.internalServerError');
  }
  if (error && error.code && error.message) {
    return `[${error.code}]: ${error.message}`;
  }
  if (error && error.code) {
    return `${error.code}`;
  }
  return `${error}`;
};
