// @ts-nocheck

import { createSelector } from 'redux-orm';
import { selectPath } from './router';
import { selectCurrentUserId } from './users';
import { BoardMembershipRoles } from '../constants/Enums';
import { WriteAccessEnum } from '../constants/WriteAccessTypes';
import orm from '../orm';
import { isLocalId } from '../utils/local-id';

function transformUrlToIncludePassword(url?: string, plainTextPassword?: string) {
  if (!url) {
    return '';
  }

  if (!plainTextPassword) {
    return url;
  }

  return `${url}?plainTextPassword=${plainTextPassword}`;
}

export const makeSelectCardById = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card, Project }, id) => {
      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }

      const projectModel = Project.withId(cardModel.board.projectId);
      if (!projectModel) {
        return cardModel;
      }
      const { plainTextPassword } = projectModel;

      const { ref } = cardModel;
      const attachments = cardModel
        .getOrderedAttachmentsQuerySet()
        .toRefArray()
        .map((attachment) => ({
          ...attachment,
          isCover: attachment.id === cardModel.coverAttachmentId,
          isPersisted: !isLocalId(attachment.id),
        }));

      const attachmentOneUrl = transformUrlToIncludePassword(
        attachments?.[0]?.url,
        plainTextPassword,
      );
      const attachmentTwoUrl = transformUrlToIncludePassword(
        attachments?.[1]?.url,
        plainTextPassword,
      );
      const coverUrl = cardModel
        .getOrderedAttachmentsQuerySet()
        .first((attachment) => attachment.coverUrl)?.coverUrl;
      const mediaUrl = attachmentTwoUrl || attachmentOneUrl || '';
      let mediaPreviewUrl = coverUrl || attachmentOneUrl || attachmentTwoUrl || '';
      if (!attachmentTwoUrl && mediaPreviewUrl === attachmentOneUrl && !ref.embedUrl) {
        mediaPreviewUrl = '';
      }
      return {
        ...cardModel.ref,
        coverUrl,
        isPersisted: !isLocalId(id),
        mediaUrl,
        mediaPreviewUrl,
      };
    },
  );

function checkUserBoardWriteAccess(userModel: any, boardModel: any): boolean {
  if (!userModel) {
    return false;
  }
  if (userModel.isAdmin) {
    return true;
  }

  const boardMembershipModel = boardModel.getMembershipModelForUser(userModel.id);
  return boardMembershipModel?.ref?.role === BoardMembershipRoles.EDITOR;
}

function evaluatePermissions(
  writeAccess: WriteAccessEnum,
  userHasBoardWriteAccess: boolean,
  isCardApproved: boolean,
  userIsDefined: boolean,
  userIsCardCreator: boolean,
): boolean {
  if (userHasBoardWriteAccess) {
    return true;
  }

  switch (writeAccess) {
    case WriteAccessEnum.ANONYMOUS_UNAPPROVED:
      return userIsCardCreator;
    case WriteAccessEnum.ANONYMOUS_APPROVED:
      if (isCardApproved) {
        return false;
      }

      return userIsCardCreator;
    case WriteAccessEnum.AUTHORIZED_MEMBERS_ONLY:
    default:
      return false;
  }
}

export const selectCardPermissionsForUserAndCardById = createSelector(
  orm,
  (_, id, userId) => ({ id, userId }),
  (
    { Card, Project, User, Board },
    { id: cardId, userId: currentUserId },
  ): {
    canEditCard: boolean;
    canScheduleOrDuplicateCard: boolean;
  } => {
    const result = {
      canEditCard: false,
      canScheduleOrDuplicateCard: false,
    };
    if (!cardId) {
      return result;
    }
    const cardModel = Card.withId(cardId);
    if (!cardModel) {
      return result;
    }

    const projectModel = Project.withId(cardModel.board.projectId);
    if (!projectModel) {
      return result;
    }

    const boardModel = Board.withId(cardModel.boardId);
    if (!boardModel) {
      return result;
    }

    const userModel = User.withId(currentUserId);
    const userHasBoardWriteAccess = checkUserBoardWriteAccess(userModel, boardModel);

    const writeAccessInProject = projectModel.writeAccess as WriteAccessEnum;
    const isCardApproved = cardModel.approved;
    const userIsDefined = !!currentUserId;
    const userIsCardCreator = cardModel.isCreator;

    const canScheduleOrDuplicateCard = isCardApproved && userHasBoardWriteAccess;
    const canEditCard = evaluatePermissions(
      writeAccessInProject,
      userHasBoardWriteAccess,
      isCardApproved,
      userIsDefined,
      userIsCardCreator,
    );

    return { canScheduleOrDuplicateCard, canEditCard };
  },
);

export const selectCardById = makeSelectCardById();

export const makeSelectUsersByCardId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card }, id) => {
      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }

      return cardModel.users.toRefArray();
    },
  );

export const selectUsersByCardId = makeSelectUsersByCardId();

export const makeSelectLastActivityIdByCardId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card }, id) => {
      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }

      const lastActivityModel = cardModel.getFilteredOrderedInCardActivitiesQuerySet().last();

      return lastActivityModel && lastActivityModel.id;
    },
  );

export const selectLastActivityIdByCardId = makeSelectLastActivityIdByCardId();

export const selectCurrentCard: (state: any) => any = createSelector(
  orm,
  (state) => state,
  (state) => selectPath(state).cardId,
  ({ Card }, state, id) => {
    return selectCardById(state, id);
  },
);

export const selectCardPermissionsForCurrentCardAndUser: (state: any) => any = createSelector(
  orm,
  (state) => state,
  (state) => selectPath(state).cardId,
  (state) => selectCurrentUserId(state),
  (
    { Card, Project, User, Board },
    state,
    cardId,
    currentUserId,
  ): {
    canEditCard: boolean;
    canScheduleOrDuplicateCard: boolean;
  } => selectCardPermissionsForUserAndCardById(state, cardId, currentUserId),
);

export const selectUsersForCurrentCard = createSelector(
  orm,
  (state) => selectPath(state).cardId,
  ({ Card }, id) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel.users.toRefArray();
  },
);

export const selectAttachmentsForCard = createSelector(
  orm,
  (state) => state,
  (id) => id,
  ({ Card }, state, id) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel
      .getOrderedAttachmentsQuerySet()
      .toRefArray()
      .map((attachment) => ({
        ...attachment,
        isCover: attachment.id === cardModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
  },
);

export const selectAttachmentsForCurrentCard = createSelector(
  orm,
  (state) => selectPath(state).cardId,
  ({ Card }, id) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel
      .getOrderedAttachmentsQuerySet()
      .toRefArray()
      .map((attachment) => ({
        ...attachment,
        isCover: attachment.id === cardModel.coverAttachmentId,
        isPersisted: !isLocalId(attachment.id),
      }));
  },
);

export const makeSelectAttachmentForCardId = () =>
  createSelector(
    orm,
    (_, id) => id,
    ({ Card }, id) => {
      if (!id) {
        return id;
      }

      const cardModel = Card.withId(id);

      if (!cardModel) {
        return cardModel;
      }

      const attachment = cardModel.getOrderedAttachmentsQuerySet().first();
      attachment.isCover = attachment.id === cardModel.coverAttachmentId;
      attachment.isPersisted = !isLocalId(attachment.id);

      return attachment;
    },
  );

export const selectAttachmentForCardId = makeSelectAttachmentForCardId();

export const selectActivitiesForCurrentCard = createSelector(
  orm,
  (state) => selectPath(state).cardId,
  (state) => selectCurrentUserId(state),
  ({ Card }, id, currentUserId) => {
    if (!id) {
      return id;
    }

    const cardModel = Card.withId(id);

    if (!cardModel) {
      return cardModel;
    }

    return cardModel
      .getFilteredOrderedInCardActivitiesQuerySet()
      .toModelArray()
      .map((activityModel) => ({
        ...activityModel.ref,
        isPersisted: !isLocalId(activityModel.id),
        user: {
          ...activityModel.user.ref,
          isCurrent: activityModel.user.id === currentUserId,
        },
      }));
  },
);
