import { getSafeUrl } from '../../utils/getSafeUrl.js';
import ExternalLink from '../ExternalLink/ExternalLink.js';

function MediaPreviewLink({ url, ogImage }: { url: string; ogImage?: string }) {
  const isEmpty = getSafeUrl(url) === '#';
  const hasImage = Boolean(ogImage);
  return (
    <div className="flex h-full max-w-full contain-content" data-testid="MediaPreviewLink">
      <ExternalLink
        className="text-blue100 flex max-w-full h-full w-full contain-content items-center gap-1"
        url={url}
      >
        {hasImage ? (
          <img src={ogImage} alt="" />
        ) : (
          <>
            <span className="break-all line-clamp-3">{url}</span>
            {!isEmpty && (
              <img
                src="/assets/images/icon-external-link_v2.svg"
                alt=""
                role="none"
                className="w-5 h-5 max-h-5 max-w-5 contain-strict"
              />
            )}
          </>
        )}
      </ExternalLink>
    </div>
  );
}

export default MediaPreviewLink;
