import { call, put, select } from 'redux-saga/effects';
import { closeModal } from './modals';
import { goToBoard, goToCard } from './router';
import actions from '../../../actions';
import api from '../../../api';
import * as selectors from '../../../selectors';
import { createLocalId } from '../../../utils/local-id';
import { getPlainTextPassword } from '../../../utils/plainTextPassword';
import request from '../request';

export function* createCard(listId, data, autoOpen) {
  const { boardId } = yield select(selectors.selectListById, listId);

  const nextData = {
    ...data,
    position: yield select(selectors.selectNextCardPosition, listId),
    plainTextPassword: getPlainTextPassword(boardId) || undefined,
  };

  const localId = yield call(createLocalId);

  yield put(
    actions.createCard({
      ...nextData,
      boardId,
      listId,
      id: localId,
    }),
  );

  let card;
  try {
    ({ item: card } = yield call(request, api.createCard, listId, nextData));
  } catch (error) {
    yield put(actions.createCard.failure(localId, error));
    return;
  }

  yield put(actions.createCard.success(localId, card));

  if (autoOpen) {
    yield call(goToCard, card.id);
  }
}

export function* handleCardCreate(card) {
  yield put(actions.handleCardCreate(card));
}

export function* updateCard(id, data) {
  const { boardId } = yield select(selectors.selectCardById, id);

  const updateData = {
    ...data,
    plainTextPassword: getPlainTextPassword(boardId) || undefined,
  };

  yield put(actions.updateCard(id, updateData));

  let card;
  try {
    ({ item: card } = yield call(request, api.updateCard, id, updateData));
  } catch (error) {
    yield put(actions.updateCard.failure(id, error));
    return;
  }

  yield put(actions.updateCard.success(card));
}

export function* updateCurrentCard(data) {
  const { cardId } = yield select(selectors.selectPath);

  yield call(updateCard, cardId, data);
}

const isEmptyData = (data) => {
  return !data.name && !data.description && !data.embedUrl && !data.mediaUrl;
};

// TODO: handle card transfer
export function* handleCardUpdate(card) {
  yield put(actions.handleCardUpdate(card));
}

export function* moveCard(id, listId, index) {
  const position = yield select(selectors.selectNextCardPosition, listId, index, id);

  yield call(updateCard, id, {
    listId,
    position,
  });
}

export function* moveCurrentCard(listId, index) {
  const { cardId } = yield select(selectors.selectPath);

  yield call(moveCard, cardId, listId, index);
}

export function* transferCard(id, boardId, listId, index) {
  const { cardId: currentCardId, boardId: currentBoardId } = yield select(selectors.selectPath);
  const position = yield select(selectors.selectNextCardPosition, listId, index, id);

  if (id === currentCardId) {
    yield call(goToBoard, currentBoardId);
  }

  yield call(updateCard, id, {
    boardId,
    listId,
    position,
  });
}

export function* transferCurrentCard(boardId, listId, index) {
  const { cardId } = yield select(selectors.selectPath);

  yield call(transferCard, cardId, boardId, listId, index);
}

export function* deleteCard(id) {
  const { cardId, boardId } = yield select(selectors.selectPath);

  const data = {
    plainTextPassword: getPlainTextPassword(boardId) || undefined,
  };

  if (id === cardId) {
    yield call(goToBoard, boardId);
  }

  yield put(actions.deleteCard(id, data));

  let card;
  try {
    ({ item: card } = yield call(request, api.deleteCard, id, data));
  } catch (error) {
    yield put(actions.deleteCard.failure(id, error));
    return;
  }

  yield put(actions.deleteCard.success(card));
}

export function* deleteCurrentCard() {
  const { cardId } = yield select(selectors.selectPath);

  yield call(deleteCard, cardId);
}

export function* cardModalClose(data) {
  if (isEmptyData(data)) {
    yield call(deleteCurrentCard);
    return;
  }
  yield call(goToBoard, data.boardId);
}

export function* cardModalDiscardAndClose() {
  const project = yield select(selectors.selectCurrentProject);
  const board = yield select(selectors.selectCurrentBoard);
  yield call(goToBoard, board.id, project.name);
}

export function* handleCardDelete(card) {
  const { cardId, boardId } = yield select(selectors.selectPath);

  if (card.id === cardId) {
    yield call(goToBoard, boardId);
  }

  yield put(actions.handleCardDelete(card));
}

export function* duplicateCard(card, targetProjectId) {
  yield put(actions.duplicateCard(card, targetProjectId));

  let item;
  try {
    ({ item } = yield call(request, api.duplicateCard, card.id, card, targetProjectId));
  } catch (error) {
    yield put(actions.duplicateCard.failure(card.id, error));
    return;
  }

  yield put(actions.duplicateCard.success(item));
  // hack
  yield call(closeModal);
}
