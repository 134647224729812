import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

function MediaRemovePreviewImageButton({ handleDelete }: { handleDelete: () => void }) {
  const [t] = useTranslation();
  return (
    <div
      className={classNames('absolute bg-transparent flex items-center justify-center', 'inset-0')}
    >
      <div className="p-8 bg-blue70 flex flex-col items-center gap-3">
        <button
          className="flex justify-center items-center p-3 border-none rounded-sm bg-blue70 text-white100 hover:bg-blue30 text-white100"
          aria-label={t('common.deleteFile')}
          type="button"
          title={t('common.deleteFile')}
          onClick={handleDelete}
        >
          <img src="/assets/images/icon-trash-white.svg" alt="" />
        </button>
        <p className="text-white">{t('common.changePreviewPicture')}</p>
      </div>
    </div>
  );
}

export default MediaRemovePreviewImageButton;
