/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { LegacyRef } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonType from '../../constants/ButtonType.js';
import { Input } from '../../lib/custom-ui';
import type { Profession } from '../../types/Profession.js';
import Button from '../Button/Button.js';
import ColorChooser from '../ColorChooser/ColorChooser.js';
import Form from '../Form/Form';
import FormError from '../FormError/FormError';
import MediaUpload from '../MediaUpload/MediaUpload.js';
import ProfessionsComboBox from '../ProfessionsComboBox/ProfessionsComboBox.js';

function BoardSettingsModalContent({
  isSubmitting,
  handleSubmit,
  data,
  handleFieldChange,
  nameField,
  availableProfessions,
  error,
  onThumbnailUpdate,
  thumbnailUrl,
  onThumbnailDelete,
  isThumbnailUpdating,
  mediaPreviewUrl,
  canEdit,
  colors,
  onExport,
  canExport,
}: {
  isSubmitting: boolean;
  handleSubmit: () => void;
  data: {
    name: string;
    subtitle: string;
    professions: readonly Profession[];
    background: string;
  };
  handleFieldChange: (...args: any[]) => void;
  nameField: LegacyRef<Input>;
  availableProfessions: readonly Profession[];
  error: any;
  onThumbnailUpdate: ({ file }: { file: File | null }) => void;
  onThumbnailDelete: () => void;
  thumbnailUrl: string;
  isThumbnailUpdating: boolean;
  canEdit: boolean;
  mediaPreviewUrl?: string;
  colors: readonly string[];
  onExport: () => void;
  canExport: boolean;
}) {
  const [t] = useTranslation();
  const handlePreviewFileSelect = () => {
    // noop
  };

  const handlePreviewFileDelete = () => {
    // noop
  };

  const setDefaultPreviewDeleted = () => {
    // noop
  };

  return (
    <div className="bg-white text-blue100 p-8 pr-6 pb-6">
      <header className="flex items-center gap-2">
        <img src="/assets/images/icon-add-document-green.svg" alt="" />
        <span className="text-2xl text-blue100 font-bold">{t('common.boardSettings')}</span>
      </header>

      <Form onSubmit={handleSubmit} className="mt-2">
        <p className="mt-4 m-0">{t('common.title')}</p>
        <Input
          fluid
          inverted
          ref={nameField}
          name="name"
          value={data.name}
          readOnly={isSubmitting}
          onChange={handleFieldChange}
        />
        <p className="mt-4 m-0">{t('common.subtitle')}</p>
        <Input
          fluid
          inverted
          name="subtitle"
          value={data.subtitle}
          readOnly={isSubmitting}
          onChange={handleFieldChange}
        />
        <p className="mt-4 m-0">{t('common.professionOrProfessions')}</p>
        <ProfessionsComboBox
          availableProfessions={availableProfessions}
          data={data}
          handleFieldChange={handleFieldChange}
        />
        <p className="mt-4 m-0">{t('common.thumbnail')}</p>
        <small className="text-xs font-light text-blue50">{t('common.imageShouldBe16x9')}</small>
        <div className="mt-1" />
        <MediaUpload
          media={thumbnailUrl}
          handleFileSelect={onThumbnailUpdate}
          handleFileDelete={onThumbnailDelete}
          isMediaUpdating={isThumbnailUpdating}
          canEdit={canEdit}
          mediaPreviewUrl={mediaPreviewUrl}
          handlePreviewFileSelect={handlePreviewFileSelect}
          handlePreviewFileDelete={handlePreviewFileDelete}
          defaultPreviewDeleted
          setDefaultPreviewDeleted={setDefaultPreviewDeleted}
        />
        <ColorChooser
          colors={colors}
          currentColor={data.background}
          setCurrentColor={(value) => {
            handleFieldChange(null, {
              name: 'background',
              value,
            });
          }}
          label={t('common.chooseBoardColor')}
        />
        <FormError error={error} />
        <div className="flex justify-between mt-5">
          {canExport && (
            <Button
              type="button"
              disabled={isSubmitting}
              variant={ButtonType.Blue}
              onClick={onExport}
            >
              {t('common.exportProject')}
            </Button>
          )}

          <Button
            type="submit"
            disabled={isSubmitting}
            variant={ButtonType.Green}
            className={canExport ? '' : 'ml-auto'}
          >
            {t('action.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default React.memo(BoardSettingsModalContent);
