import classnames from 'classnames';
import { getFileName } from '../../utils/getFileName.js';
import { isImage } from '../../utils/isImage.js';
import ExternalLink from '../ExternalLink/ExternalLink';

const getImageSrc = (
  customImageUpload: boolean,
  url: string,
  mediaPreviewUrl: string,
  minimal: boolean,
): string => {
  if (customImageUpload && mediaPreviewUrl && !minimal) {
    return mediaPreviewUrl;
  }
  if (customImageUpload && isImage(url) && !minimal) {
    return url;
  }
  return '';
};

function MediaPreviewFile({
  url,
  icon = '/assets/images/icon-file.svg',
  iconSize = 15,
  mediaLinkUrl,
  mediaPreviewUrl,
  customImageUpload,
  minimal,
  isUploadPreview,
  defaultPreviewDeleted,
}: {
  url: string;
  icon?: string;
  iconSize?: number;
  mediaLinkUrl?: string;
  mediaPreviewUrl?: string;
  customImageUpload?: boolean;
  minimal?: boolean;
  isUploadPreview?: boolean;
  defaultPreviewDeleted?: boolean;
}) {
  const linkUrl = mediaLinkUrl || url;
  const fileName = getFileName(linkUrl);
  const imageSrc = getImageSrc(customImageUpload, url, mediaPreviewUrl, minimal);
  const showPreviewPicture = imageSrc && !defaultPreviewDeleted;
  return (
    <div className="contain-content" data-testid="MediaPreviewFile">
      <ExternalLink className="text-blue100 flex gap-2 flex-col contain-content" url={linkUrl}>
        {showPreviewPicture && (
          <div>
            <img
              src={imageSrc}
              alt=""
              className="h-full max-h-full object-contain min-h-[100px] min-w-[100px] w-full"
            />
          </div>
        )}

        {!isUploadPreview && (
          <div className="flex gap-1">
            {!showPreviewPicture && (
              <img
                src={icon}
                alt=""
                width={iconSize}
                height={iconSize}
                className="max-w-[50px] max-h-[25px] object-contain"
              />
            )}
            <span
              className={classnames(
                'text-blue50 hover:text-blue100 flex items-center shrink-0',
                showPreviewPicture && 'text-sm', // we don't want to make it too small
              )}
            >
              {fileName}
            </span>
          </div>
        )}
      </ExternalLink>
    </div>
  );
}

export default MediaPreviewFile;
