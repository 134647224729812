import * as MediaPreviewType from '../constants/MediaPreviewType';

export const requiresConsent = (mediaPreviewType: number) => {
  switch (mediaPreviewType) {
    case MediaPreviewType.EmbedGoogleMaps:
    case MediaPreviewType.EmbedInstagram:
    case MediaPreviewType.EmbedSoundcloud:
    case MediaPreviewType.EmbedTwitter:
    case MediaPreviewType.EmbedYoutube:
    case MediaPreviewType.EmbedVimeo:
    case MediaPreviewType.EmbedYoutubeShort:
      return true;
    default:
      return false;
  }
};
